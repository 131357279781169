html {
  height: 100%;
  scroll-behavior: smooth;
}

p {
  margin: 0;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.form-check-input {
  transition: all 0.3s;
  &:checked {
    background-color: var(--base-color);
    border-color: var(--base-color);
  }
  &:focus {
    box-shadow: 0px 0px 11px 0px var(--base-color);
    border: 1px solid var(--base-color) !important;
  }
}

.profile-container {
  background-color: color(50);
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  border-radius: 50%;
  border: 5px solid color(50);
  box-shadow: 0 0 0 5px color(50);
  width: 250px;
  height: 250px;
}

.profile-label {
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.img-preview {
  overflow: hidden;
}

.navbar-collapse {
  @include media-breakpoint-down(lg) {
    background: rgba(color(900), 0.8);
    backdrop-filter: blur(3px);
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;

    & > div {
      display: flex !important;
      flex-direction: column !important;
      align-items: initial !important;

      .dropdown-menu {
        position: static;
        box-shadow: none;
        background: transparent;

        a {
          // color: color(50) !important;
        }
      }
    }
  }
}

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  h5 {
    font-size: 5vw;
    letter-spacing: 3vw;
    text-transform: uppercase;
    margin-bottom: 0px;
    padding-left: 3vw;
  }

  h1 {
    font-size: 14vw;
    font-weight: bold;
    margin-bottom: 0px;
  }

  @include media-breakpoint-up(md) {
    h5 {
      font-size: 1.7vw;
      letter-spacing: 0.8vw;
      padding-left: 0.8vw;
    }

    h1 {
      font-size: 6vw;
    }
  }
}

body {
  font-size: 14px;
  line-height: 22px;
  font-family: font(rg);
  // background: #edf3f9;
  color: color(900);
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }

  a {
    color: inherit;
  }

  a,
  .btn {
    text-decoration: none;

    &:hover {
      text-decoration: none;
      @include transition(all 0.4s);
    }
  }

  .player-profile {
    img {
      object-fit: contain;
      border-radius: 20px;
      overflow: hidden;
    }

    @include media-breakpoint-down(md) {
      .col-md-4 {
        text-align: center;
      }

      img {
        margin: -15px 0px 10px 0px;
      }

      h4,
      .otherInfo {
        text-align: center;
      }

      img {
        height: 70px;
        border-radius: 10px;
        width: 70px !important;
      }
    }
  }

  .player-login {
    display: flex;
    letter-spacing: 0.5px;

    .wrapper {
      flex-grow: 1;
      transition: all 0.3s;
      padding-bottom: 40px;

      .content {
        min-height: calc(100vh - 145px);
        width: calc(100vw - 256px);
        transition: all 0.3s;
      }
    }

    @include media-breakpoint-down(xl) {
      .wrapper {
        // padding-left: 0px;
      }

      .content {
        width: 100vw !important;
      }
    }
  }

  &.toggleSidebar {
    .player-login {
      .wrapper {
        // padding-left: 70px;

        .content {
          width: calc(100vw - 76px);
        }
      }

      @include media-breakpoint-down(xl) {
        .wrapper {
          // padding-left: 0px;
        }

        padding-left: 0px !important;

        .content {
          width: calc(100vw) !important;
        }

        .sidebar {
          width: 0px !important;
          overflow: hidden;
        }
      }

      @include media-breakpoint-down(md) {
        .content {
          padding: 0px !important;
        }
      }
    }
  }
}

.changeColor {
  position: fixed;
  background: color(50);
  z-index: 100;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
  right: 0;
  top: calc(50% - 50px);
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.quicklinkHolder canvas {
  height: 35px;
  min-width: 35px;
  filter: brightness(1) blur(0.5px);
}

a.stretched-link {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

div[class*="col-"] {
  position: relative;
}

.p-multiselect {
  border-radius: 6px;
  min-height: 35px;
  width: 100% !important;

  &-panel {
    z-index: 100000001 !important;
  }

  &-label {
    font-size: 14px;
  }
}

.p-icon {
  height: 15px;
  width: 15px;
  color: rgb(99, 99, 99);
}

.rbc-addons-dnd {
  .rbc-addons-dnd-resize-ns-anchor {
    width: 100%;
    text-align: center;
    position: absolute;
    height: 5px;
    left: 0;
    z-index: 100;

    .rbc-addons-dnd-resize-ns-icon {
      border-top: 3px double;
      margin: 0 auto;
      width: 10px;
      cursor: ns-resize;
    }
  }

  // Select the first anchor element
  &-resizable .rbc-addons-dnd-resize-ns-anchor:first-child {
    top: 0;
  }

  // Select the second anchor element
  &-resizable .rbc-addons-dnd-resize-ns-anchor:last-child {
    bottom: 0;
  }

  &-calendar {
    height: calc(100vh - 240px);
  }

  &-addons-dnd-dragged-event {
    opacity: 0;
  }
}

.eventWrapper {
  .title {
    font-size: 12px !important;
    padding: 8px 10px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  small {
    line-height: 1rem;
  }
}

.rbc {
  &-btn-group {
    .dropdown * {
      font-size: 13px;
    }
  }

  &-allday-cell {
    display: none;
  }

  &-time-gutter {
    z-index: 100000 !important;
  }

  &-addons-dnd-resizable {
    width: 100%;
  }

  &-addons-dnd-dragged-event {
    opacity: 0;
  }

  &-calendar {
    height: calc(100vh - 240px);
  }

  &-time-header {
  }

  &-time-header-gutter {
    min-width: 74.0781px !important;
  }

  &-time-header-content {
    min-width: 280px !important;
    max-width: 280px;
  }

  &-time-view-resources {
    .rbc-day-slot {
      // min-width: 280px !important;
      // max-width: 280px;
      border-right: 2px solid #ddd;
    }
  }

  &-header {
    font-size: 14px;
    height: auto;
    // min-width: 280px !important;
    // max-width: 280px;
    // min-height: 40px;
    line-height: 30px;
    border-right: 2px solid #ddd;
  }

  &-timeslot-group {
    min-height: 70px;
  }

  &-today {
    background-color: color(50) !important;
    min-width: 220px;
  }

  &-event {
    background-color: color(220);
    border-radius: 10px;
    border-width: 0;
    padding: 3px 7px !important;
    box-shadow: 0px 5px 30px 0px rgba(color(220), 0.5);
    z-index: 1000;
    display: flex !important;
    align-items: center;
    border-width: 0px !important;
    overflow: hidden;
    transition: all 0.3s;
    color: #000 !important;

    &.no_edit {
      pointer-events: none;
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;

      & > * {
        display: none;
      }

      background: #dfdfdf !important;
      color: transparent;
      box-shadow: none;
      border-radius: 0;

      // width: calc(initial + 20px)!important;
      // pointer-events: none;
      hr {
        display: none;
      }
    }
  }

  &-toolbar-label {
    font-size: 14px;

    select,
    button {
      font-size: 14px;
      border-radius: 5px !important;
      margin-right: 5px;
      width: auto;
    }
  }

  &-btn-group {
    .dropdown {
      .btn {
        font-size: 14px;
        border-radius: 5px !important;
        margin-right: 5px;
        padding: 5px 10px !important;
        width: auto;
      }
    }

    select,
    button {
      font-size: 14px;
      border-radius: 5px !important;
      margin-right: 5px;
      width: auto;
    }

    &.exportBtn button {
      background: #4caf50;
      color: color(50);
    }
  }

  &-event-label {
    display: block !important;
    width: 100%;
  }

  &-label {
    font-size: 14px;
  }

  &-event-content {
    min-height: 1em;
    // margin-top: 7px;
  }

  &-time-slot {
    flex: 1 0;
    align-items: center;
    display: flex;
  }

  &-event-label {
    display: none !important;
  }

  &-timeslot-group {
    min-height: 55px !important;
  }

  &-toolbar {
    position: relative;
    z-index: 10000;

    @include media-breakpoint-down(md) {
      flex-direction: row !important;
      display: flex;

      .btn,
      button {
        padding: 5px 10px;
      }

      &-label {
        flex-direction: row !important;
        width: 33.3%;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
//     min-width: 220px;
// }
.draggableForm {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(color(900), 0.3);
  z-index: 100001;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    background: color(50);
    padding: 1.3rem;
    border: 1px solid color(140);
    position: relative;
    z-index: 1000;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
  }
}

h2.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid var(--accent-color); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation:
    typing 3.5s steps(40, end),
    blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--accent-color);
  }
}

.dropdown.nav-items {
  position: relative;
  display: inline-block;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 150px;
    z-index: 1;

    left: 0;
    min-width: 100%;
    // right: 0;

    a,
    span {
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      white-space: pre;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }
    }
  }
}
.dropbtn {
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a,
.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  white-space: pre;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-content a:hover,
.dropdown-content span:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown.nav-items:hover .dropdown-content {
  display: block;
  margin-top: 10px;
}

.dropdown.nav-items-active {
  position: relative;
  display: inline-block;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 150px;
    z-index: 1;
    left: 0;
    min-width: 100%;
    // right: 0;

    a,
    span {
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      white-space: pre;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }
    }
  }
}

.dropdown.nav-items-active:hover .dropdown-content {
  display: block;
  margin-top: 10px;
}

.lt_dropdown {
  position: absolute;
  top: 8px;
  right: -10px;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 120px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
  }

  &:hover .dropdown-content {
    display: block;
    left: -30px;
    top: 20px;
    border-radius: 5px;
  }
}

.event {
  // display: flex;
  cursor: move;
  align-items: center;
  transition: all 0.3s;

  &:hover {
    .actions {
      opacity: 1;

      svg {
        margin-top: 0 !important;
        opacity: 1;
        cursor: pointer;
      }
    }

    .bottomArrow {
      opacity: 1;
      pointer-events: all;
    }
  }

  .title {
  }

  .actions {
    position: absolute;
    height: calc(100%);
    width: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(color(900), 0.7), rgba(color(900), 0));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    opacity: 0;
    transition: all 0.3s;

    svg {
      color: color(900);
      font-size: 20px;
      width: 35px;
      right: -10px;
      margin-top: -70px !important;
      background: #fff;
      height: 35px;
      border-radius: 10px;
      padding: 10px;
      transition: all 0.3s;
      opacity: 0;
    }
  }

  * {
    // max-width: 170px;
  }

  .img {
    color: color(50);
    background: url("../../images/tennis.png");
    top: 10px;
    left: 10px;
    border-radius: 100px;
    background-color: rgba(color(50), 0.5);
    height: 30px;
    min-width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    float: left;
  }

  .bottomArrow {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    background: linear-gradient(0deg, rgba(color(900), 0.5), rgba(color(900), 0));
    padding: 10px 0;
    opacity: 0;
    pointer-events: none;
  }
}

// .ltTable table tr th:first-child,
// .ltTable table tr td:first-child {
//     position: sticky;
//     left: 0;
//     padding: 10px;
//     z-index: 1;
// }

.ltTable table tr th:first-child {
  background: #edf2f9;
}

.ltTable table tr td:first-child {
  background: #fff;
}

.reCaptcha {
  margin: 5px 0px;
}

div#rc-anchor-container {
  width: 100%;
}

.calendar_default_event_inner {
  background: color(220);
  color: color(50);
  border: none;
  border-radius: 5px;
  font-size: 10pt;
  padding: 5px;
  opacity: 0.8;
}

.addEventModal {
  position: absolute;
  width: 300px;
  z-index: 10000;
  display: none;
  transition: all 0.3s;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
}

.statusUpdate {
  cursor: pointer;

  input {
    height: 0;
    width: 0;
  }
}

.base_gredient {
  background: linear-gradient(45deg, color(245), color(240), color(235));
  background-image: linear-gradient(45deg, color(245), color(240), color(235));
}

.circleBg {
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    height: 160px;
    width: 160px;
    background: rgba(color(50), 0.1);
    position: absolute;
    border-radius: 140px 0px 140px 0px;
    right: -60px;
    top: -58px;
    // box-shadow: 0px 0px 40px 0px rgba(color(900), 0.1);
  }

  &:before {
    content: "$";
    display: block;
    height: 100px;
    width: 100px;
    background: rgba(color(50), 0.1);
    position: absolute;
    border-radius: 140px 0px 140px 0px;
    right: -30px;
    top: -30px;
  }
}

.filterOption {
  margin-bottom: 10px;

  input,
  select {
    width: 200px;
  }

  input {
    &[type="date"] {
      width: 150px;
    }
  }

  & > * {
    &:not(label) {
    }
  }

  .btn {
    border: 1px solid color(150);
    background: color(50);
    color: color(150);
    padding: 0.35rem 0.7rem;

    @include media-breakpoint-down(md) {
      font-size: 0.8rem;
      padding: 0.45rem;
    }
  }

  @include media-breakpoint-down(xl) {
    input,
    select {
      width: 150px;
    }
  }
}

.Toastify {
  &__toast-container {
    z-index: 1000000000;
  }
}

.switch {
  display: inline-block;

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.version_1 {
    label {
      height: 35px;
      border-radius: 5px;

      &:after {
        border-radius: 2px;
        height: 29px;
      }
    }
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 38px;
    height: 21px;
    background: color(150);
    display: block;
    border-radius: 100px;
    position: relative;
    transition: all 0.3s;
  }

  label:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 15px;
    height: 15px;
    background: color(50);
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: color(500);
  }

  input:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 20px;
  }

  // centering
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}

div:where(.swal2-container) .swal2-html-container {
  font-size: inherit !important;
}

div:where(.swal2-container) {
  z-index: 1000000000 !important;
}

.homepage {
  .sidebar {
    display: none;
  }

  .footer {
    display: none;
  }

  .wrapper {
    & > .navbar {
      display: none;
    }
  }
}

body.scrolled .color2 {
  fill: #000;
}

.checkicon * {
  height: 30px;
  width: 30px;
  background: #e9e9e9;
  padding: 5px;
  stroke: white;
  border-radius: 10px;
}

.filterSection {
  background: rgba(48, 47, 140, 0.129);
  padding: 20px;
}

.react-date-picker {
  padding: 3.6px 6px !important;

  &__clear-button {
    display: none;
  }
}

.halfBg {
  position: relative;

  & > * {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    display: block;
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--base-color);
    z-index: 0;
  }
}

.bgHeading {
  position: relative;
  padding: 200px 0 60px;
  display: flex;
  align-items: center;

  &:after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
  }
  h4 {
    color: color(50);
    font-size: 30px;
    position: relative;
    z-index: 1;
  }

  .img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: right !important;
  }
}

.pageHeading {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  &-title {
    font-size: 1.3em;
    margin-bottom: 0;
    text-transform: capitalize;
    font-weight: 600;
    padding: 25px 0px;
    color: color(230);
    flex-grow: 1;

    span {
      display: inline-block;
      margin-right: 10px;
    }
  }

  &-options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > * {
      margin-right: 10px;
      margin-left: 0px !important;
    }
  }

  @include media-breakpoint-down(xl) {
    &-title {
      padding: 20px 0px;
    }

    &-options {
      & > * {
        margin-left: 10px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &-title {
      padding: 15px 0px;
      font-size: 18px;
    }
  }

  @include media-breakpoint-down(sm) {
    //   &-title {
    //     padding: 10px 0px;
    //     font-size: 16px;
    //   }
    flex-direction: column;
    align-items: baseline;

    &-options {
      :first-child {
        margin-left: 0px;
      }

      & > * {
        flex-grow: 1;

        & > * {
          width: 100% !important;

          & > * {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.minh-500 {
  min-height: 500px;
}

// .modal-content {
//     overflow: hidden;
// }
.profile_card {
  background: white;
  // padding: 4.3rem 1.3rem 1.3rem;
  // margin: -4.3rem -2.5rem 0rem;
}

.full-rounded {
  border-radius: 100%;
}

.mw-500 .tooltip-inner {
  max-width: 100%;
}

.tooltip {
  font-size: 1em;
  position: absolute !important;
  z-index: 100000000000;
  // transition: all .3s;
}

.profileCircle {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.camaraicon {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  border-radius: 50px;
  font-size: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.3);

  input[type="file"] {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
}

.react_select.errorInput {
  position: relative;
  z-index: 1;
}

.errorInput div[class*="-control"],
.form-control.errorInput {
  border: 1px solid red !important;
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.profile {
  background: color(50);
  overflow: hidden;
  border-radius: 100%;
  border: 1px solid color(150);
  position: relative;

  &:hover {
    .camaraicon {
      opacity: 1;
    }
  }

  &__lg {
    height: 120px;
    min-width: 120px;
    max-width: 120px;
  }

  &__md {
    height: 80px;
    min-width: 80px;
    max-width: 80px;
  }

  &__sm {
    height: 40px;
    min-width: 40px;
    max-width: 40px;
  }

  & > img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
    border-radius: 100%;
  }
}

.nowrap {
  white-space: pre;
}

.dropdown-toggle {
  .btn-sm,
  .btn {
    font-size: 14px;
  }
}

input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}

input[type="date"] {
  color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: #666666;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "";
}

label[aria-required]:after {
  content: "*";
  color: red;
  font-size: 20px;
}

.form-control {
  font-size: 1em;
  min-height: calc(1.8125rem + 2px);
}

span.eyeicon {
  position: absolute;
  right: 20px;
  top: 29px;
  font-size: 16px;
  opacity: 0.7;
}

hr {
  border-color: color(110);
  opacity: 1;
}

.react-date-picker {
  &__wrapper {
    border-width: 0px !important;
  }
}

.position-sticky {
  position: sticky;
  // top: 10px;
}

.profile_progress {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;

  &_bar {
    width: 0;
    height: 30px;
    background-color: #4caf50;
    text-align: center;
    line-height: 30px;
    color: white;
    transition: width 0.5s ease-in-out;
    // animation: fillProgress 3s ease-in-out forwards;
  }
}

.error {
  color: red;
}

.cursor-pointer {
  cursor: pointer;
}

.badge {
  padding: 5px 7px;
  font-size: 0.9em;

  &.bg {
    &-warning {
      background: color(450) !important;
      color: color(900) !important;
    }

    &-danger {
      background: color(350) !important;
      color: color(50) !important;
    }

    &-success {
      background: color(600) !important;
      color: color(50) !important;
    }

    &-primary {
      background: color(210) !important;
      color: color(50) !important;
    }

    &-info {
      background: color(700) !important;
      color: color(900) !important;
    }
  }
}

.badgeStatus {
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked {
      & + span {
        background: green;
      }
    }

    & + span {
      background: red;
    }
  }
}

.selectedColor {
  height: 15px;
  width: 15px;
  overflow: hidden;
  border-radius: 100%;
  display: block;
}

input.translation_input {
  border-color: transparent;

  color: #5e6e82;
  background: transparent;

  &:focus {
    border: 1px solid #5e6e82;
  }
}

* .text-success {
  color: #4caf50 !important;
}

.uploader {
  &_imgHolder {
    display: flex;
    flex-wrap: wrap;
  }

  &_image {
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    position: relative;
    // padding: 0 10px;
    overflow: hidden;
    height: 90px;
    width: 90px;
    margin-bottom: 7px;
    margin-right: 7px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      background: color(50);
    }
  }

  &_image_action {
    position: absolute;
    top: 0;
    left: 0;
  }

  &_image_action {
    button {
      background: color(235);
      color: color(50);
      border-width: 0px;
      border-radius: 30px;
      margin-left: 3px;
      margin-top: 3px;
      width: 24px;
      height: 24px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.h-100 {
  height: calc(100% - 1rem) !important;
}

.yearDropdown {
  .dropdown-menu {
    max-height: 250px;
    overflow: auto;
  }
}

path[fill="red"] {
  fill: inherit;
}

.react-select {
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

body .react_select div[class*="-indicatorContainer"] {
  background-repeat: no-repeat !important;
  background-position: calc(100% - 3px) center !important;
  background-size: 18px !important;
}

.form-select {
  font-size: 1em;
}

.slick-slide img {
  width: 100%;
}

.sliderDemo {
  .slick-prev {
    left: 5px;
    z-index: 1;
    background: color(50);
    border-radius: 100%;
    line-height: 40px;
  }

  .slick-next {
    right: 5px;
    z-index: 1;
    background: color(50);
    border-radius: 100%;
    line-height: 40px;
  }

  .slick-next:before,
  .slick-prev:before {
    color: color(235) !important;
  }
}

.rdw-editor-wrapper {
  box-sizing: content-box;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.absoluteRight {
  position: absolute;
  right: 10px;
  top: 10px;
}

// .time-picker div[class*="-control"] {
//     border-radius: 0px !important;
//     border-top-width: 0px;
//     border-bottom-width: 0px;
// }

// .time-picker div[class*="-IndicatorsContainer"] {
//     display: none;
// }

// .time-picker div[class*="-control"] {
//     border-width: 0 !important;
// }
